import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


const USER = 'user'
function User() {
    const [user, setUser] = useState('')
    const navigate = useNavigate();
    function handleChangeUser(e) {

        setUser(e.target.value)
        localStorage.setItem(USER, e.target.value)
        console.log(e.target.value)
    }
    return (<div className='App'>
        <input type="text" value={user} onChange={handleChangeUser} />
        <button onClick={() => navigate(0)}>Save user</button>
    </div>)
}

export default User;
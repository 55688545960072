/*


- write log
- component for train (rectangle with doors)
- button slow down/speedup
- button show/hide 
- log per train
- info per train



0 
0 - sim realistic list of the stations
0 - calc end-to-end stats
0 - round trip


0- add backphoto/ or draw lines
0- show train (dot/)
0- add transition

0- params map CSV



1
param loaders (from textbox or table)
param presenter (jsonview-er)

2-1
url param reader
url param writer (on button or directly)


4-2
time simulator with textbox
time factoring (500x)
param v,s,a,t presenter textual

6-2
param presenter visual
signal presenter

8-2
train positioner

10-2
time calculator
circular calculator
(spin calculator)

15-5
Visuals:
train:
 - worms
 - tubes with circle at top
 - circle with sign (-) , (|) , (/\), (\/) )
 - tube with animations
 - wider station
 - ratio's ... 

 - sankey

progress:
 - progress bar

speedometer:

forcemeter: 
 - comfort


 DONE:
 ==========
 2024-05-07 cleanup
 2024-05-07 add typescript
 2024-05-07 add acceleration
  2024-05-07 add simple visual



*/


import React, { useState } from 'react';
import MetroInfo from './MetricInfo.tsx';
// import { useEffect } from 'react';


const END_OF_PROCESS = 'end'
const NO_TIMER = -1
const TICK_INCREMENT = .5

const l = (...args) => { console.log(...args) }//log

function initMetrics(name: string, space: number, dirSpace: number = space, state: number = 0, direction: boolean = true, timeRelative:number = 0): Metrics {
    return {
        train: name,
        currentState: state,
        pendingCalc: 0,
        spaceTotal: space,
        spaceRelative: 0,
        direction: direction,
        directionSpace: dirSpace,
        timeRelative: timeRelative,
        timeTotal: 0,
        velocity: 0,
        acceleration: 0
    }
}

function createWaitState(stopDuration: number, stationName: string): State {
    const phaseName = 'waiting'
    return {
        phaseName,
        stationName,
        stopDuration,
        init: (oldMetric: Metrics): Metrics => {
            //l('distance on arrival ' + oldMetric.train + ' :', oldMetric.spaceTotal)
            let newMetric = { ...oldMetric }
            newMetric.velocity = 0
            newMetric.acceleration = 0
            newMetric.spaceRelative = 0
            newMetric.timeRelative = 0
            return newMetric
        },
        tick: (oldMetric: Metrics, dt: number): Metrics => {
            let newMetric = { ...oldMetric }
            let realDt: number
            if (newMetric.timeRelative + dt > stopDuration) {
                realDt = stopDuration - newMetric.timeRelative
                newMetric.pendingCalc = dt - realDt
            } else {
                realDt = dt
                newMetric.pendingCalc = 0
            }
            newMetric.timeTotal += realDt
            newMetric.timeRelative += realDt
            // l(stationName, phaseName, newMetric)
            if (newMetric.timeRelative >= stopDuration) {
                newMetric.currentState++
                return newMetric
            } else return newMetric
        }
    }
}

function createAccelerateState(duration: number, stationName: string, acceleration: number): State {
    const phaseName = 'acceleration'
    return {
        phaseName,
        stationName,
        acceleration,
        init: (oldMetric: Metrics): Metrics => {
            let newMetric = { ...oldMetric }
            // newMetric.velocity = velocity
            newMetric.acceleration = acceleration
            newMetric.spaceRelative = 0
            newMetric.timeRelative = 0
            return newMetric
        },
        tick: (s: Metrics, dt: number): Metrics => {
            let newS = { ...s }

            let realDt: number
            if (newS.timeRelative + dt > duration) {
                realDt = duration - newS.timeRelative
                newS.pendingCalc = dt - realDt
            } else {
                realDt = dt
                newS.pendingCalc = 0
            }

            newS.timeTotal += realDt
            newS.timeRelative += realDt
            newS.velocity += realDt * s.acceleration
            let ds = realDt * s.velocity + (s.acceleration * realDt * realDt / 2)
            newS.spaceTotal += ds
            newS.spaceRelative += ds
            if (s.direction) {
                newS.directionSpace += ds
            } else {
                newS.directionSpace -= ds
            }
            // l(stationName, phaseName, newS)
            if (newS.timeRelative >= duration) {
                newS.currentState++
                return newS
            } else return newS
        }
    }
}

function createGoState(distance: number, stationName: string): State {
    const phaseName = 'going'
    return {
        phaseName,
        stationName,
        distance,
        init: (oldMetric: Metrics): Metrics => {
            let newMetric = { ...oldMetric }
            newMetric.acceleration = 0
            newMetric.spaceRelative = 0
            newMetric.timeRelative = 0
            return newMetric
        },
        tick: (s: Metrics, dt: number): Metrics => {
            let newS = { ...s }

            let realDt: number
            if (newS.spaceRelative + dt * s.velocity > distance) {
                realDt = (distance - s.spaceRelative) / s.velocity
                newS.pendingCalc = dt - realDt
            } else {
                realDt = dt
                newS.pendingCalc = 0
            }

            newS.timeTotal += realDt
            newS.timeRelative += realDt
            let ds = realDt * s.velocity
            newS.spaceTotal += ds
            newS.spaceRelative += ds
            //todo add assert that ds + s.spaceRelative <= distance
            if (s.direction) {
                newS.directionSpace += ds
            } else {
                newS.directionSpace -= ds
            }
            // l(stationName, phaseName, newS)
            if (newS.spaceRelative >= distance) {
                newS.currentState++
                return newS
            } else return newS
        }
    }
}



function createDiscreteGoState(distance: number, stationName: string, velocity: number): State {
    const phaseName = 'goingD'
    return {
        phaseName,
        stationName,
        distance,
        init: (oldMetric: Metrics): Metrics => {
            let newMetric = { ...oldMetric }
            //l('INIT discrete go')
            newMetric.velocity = velocity
            newMetric.acceleration = 0
            newMetric.spaceRelative = 0
            newMetric.timeRelative = 0
            return newMetric
        },
        tick: (s: Metrics, dt: number): Metrics => {
            let newS = { ...s }

            // l(velocity, s)
            let realDt: number
            if (newS.spaceRelative + dt * velocity > distance) {
                realDt = (distance - s.spaceRelative) / velocity
                newS.pendingCalc = dt - realDt
                // realDt = dt
            } else {
                realDt = dt
                newS.pendingCalc = 0
            }

            newS.timeTotal += realDt
            newS.timeRelative += realDt
            let ds = realDt * velocity
            newS.spaceTotal += ds
            newS.spaceRelative += ds
            if (s.direction) {
                newS.directionSpace += ds
            } else {
                newS.directionSpace -= ds
            }
            // l(stationName, phaseName, newS)
            if (newS.spaceRelative >= distance) {
                newS.currentState++
                return newS
            } else return newS
        }
    }
}


function begin(): State {
    return {
        phaseName: 'begin',
        init: (oldMetric: Metrics): Metrics => {
            let newMetric = { ...oldMetric }
            newMetric.velocity = 0
            newMetric.acceleration = 0
            newMetric.spaceRelative = 0
            newMetric.timeRelative = 0
            return newMetric
        },
        tick: (s, dt) => {
            // l('tst')
            return { ...s, currentState: s.currentState + 1 }
        }
    }
}

function changeDirection(): State {
    return {
        phaseName: 'change',
        init: (oldMetric: Metrics): Metrics => {
            let newMetric = { ...oldMetric }
            newMetric.direction = !oldMetric.direction
            return newMetric
        },
        tick: (s, dt) => {
            // l('tst')
            return { ...s, currentState: s.currentState + 1 }
        }
    }
}

function repeat(): State {
    return {
        phaseName: 'repeat',
        init: (oldMetric: Metrics): Metrics => {
            let newMetric = { ...oldMetric }
            newMetric.direction = !oldMetric.direction
            return newMetric
        },
        tick: (s, dt) => {
            // l('tst')
            return { ...s, currentState: 1 }
        }
    }
}

function end(): State {
    return {
        phaseName: END_OF_PROCESS,
        init: (oldMetric: Metrics): Metrics => {
            let newMetric = { ...oldMetric }
            newMetric.velocity = 0
            newMetric.acceleration = 0
            newMetric.spaceRelative = 0
            newMetric.timeRelative = 0
            return newMetric
        },
        tick: (s, dt) => {
            // l('tst')
            return s
        }
    }
}

/////////////////
//Behavior sets//
//////////////////

function initMetricSet(name, density = 1): MetricsSet {
    let res: Metrics[] = []
    const STATION_LENGTH = 670
    const PHASE_COUNT = 4
    for (let i = 0; i < 21; i += density) {
        res.push(initMetrics(name + '-' + i.toString(), i * STATION_LENGTH, i * STATION_LENGTH, i * 4 + 1,true))
    }
    const MAGIC = 42 //20+21
    for (let i = 22 - density; i > 0; i -= density) {
        let iNonReverse = (MAGIC - i)
        res.push(initMetrics(name + '-' + iNonReverse.toString(), iNonReverse * STATION_LENGTH, i * STATION_LENGTH, iNonReverse * 4 + 2, false))
    }

    return res

    // return [initMetrics(name + '-1', 0), initMetrics(name + '-2', 670, 1)];
}

function addTraditionalStation(behavior: Behavior, stationName: string) {
    behavior.push(createWaitState(22, stationName))
    behavior.push(createAccelerateState(22, stationName, 1))
    behavior.push(createGoState(100, stationName))
    behavior.push(createAccelerateState(29, stationName, -0.76))
}

function addFastStation(behavior: Behavior, stationName: string) {
    // behavior.push(createWaitState(22, stationName))
    // behavior.push(createAccelerateState(22, stationName, 1))
    behavior.push(createDiscreteGoState(167, stationName, 22))
    behavior.push(createDiscreteGoState(167, stationName, 22))
    behavior.push(createDiscreteGoState(167, stationName, 22))
    behavior.push(createDiscreteGoState(167, stationName, 22))
    // behavior.push(createAccelerateState(29, stationName, -0.76))
}

function createMetroLineBehavior(stationFunction) {
    let behavior: Behavior = [begin()]
    //Vienna U3 - 20 segments, 21 stations, 13500m, 1560s
    for (let i = 0; i < 21; i++) {
        stationFunction(behavior, i.toString())
    }
    behavior.push(changeDirection())
    for (let i = 20; i > -1; i--) {
        stationFunction(behavior, i.toString())
    }
    behavior.push(repeat())
    behavior.push(end())
    return behavior
}

let firstBehavior: Behavior = []
firstBehavior.push(begin())
// firstBehavior.push(createWaitState(29, '1'))
firstBehavior.push(createAccelerateState(24, '1', 0.5))
firstBehavior.push(createGoState(500, '1'))
firstBehavior.push(createAccelerateState(24, '1', -0.5))
firstBehavior.push(createWaitState(29, '2'))
firstBehavior.push(createAccelerateState(24, '2', 0.5))
firstBehavior.push(createGoState(400, '2'))
firstBehavior.push(createAccelerateState(24, '2', -0.5))
firstBehavior.push(createWaitState(27, '3'))
firstBehavior.push(createAccelerateState(24, '3', 0.5))
firstBehavior.push(createGoState(650, '3'))
firstBehavior.push(createAccelerateState(24, '3', -0.5))
// states.push(createWaitState(20, '1'))


// states.push(createDiscreteGoState(1000, '1', 20))
// states.push(createWaitState(20, '2'))
// states.push(createDiscreteGoState(900, '2', 20))
// states.push(createWaitState(20, '3'))
// states.push(createDiscreteGoState(1000, '3', 20))
firstBehavior.push(end())

let secondBehavior: Behavior = []
secondBehavior.push(begin())
// secondBehavior.push(createWaitState(29, '1'))
secondBehavior.push(createAccelerateState(24, '1', 0.5))
secondBehavior.push(createGoState(500, '1'))
secondBehavior.push(createAccelerateState(24, '1', -0.5))
// secondBehavior.push(createWaitState(29, '2'))
secondBehavior.push(createAccelerateState(24, '2', 0.5))
secondBehavior.push(createGoState(400, '2'))
secondBehavior.push(createAccelerateState(24, '2', -0.5))
// secondBehavior.push(createWaitState(27, '3'))
secondBehavior.push(createAccelerateState(24, '3', 0.5))
secondBehavior.push(createGoState(650, '3'))
secondBehavior.push(createAccelerateState(24, '3', -0.5))
// states.push(createWaitState(20, '1'))


// states.push(createDiscreteGoState(1000, '1', 20))
// states.push(createWaitState(20, '2'))
// states.push(createDiscreteGoState(900, '2', 20))
// states.push(createWaitState(20, '3'))
// states.push(createDiscreteGoState(1000, '3', 20))
secondBehavior.push(end())


// let behaviorSet: BehaviorSet = [createMetroLineBehavior(addTraditionalStation), createMetroLineBehavior(addFastStation)]
let traditionalMetroBehavior = createMetroLineBehavior(addTraditionalStation)
let fastMetroBehavior = createMetroLineBehavior(addFastStation)

function getConfigSpeed() {
    if (!!localStorage.getItem('speed')) {
        return parseInt(localStorage.getItem('speed') || '72')
    } else return 72
}

function Sim() {

    const [timerId, setTimerId] = useState(NO_TIMER);
    const [metric, setMetric] = useState(initMetrics('I', 0))

    const [enabledA, setEnabledA] = useState(true)
    const [enabledB, setEnabledB] = useState(false)

    const [metricSetA, setMetricSetA] = useState(initMetricSet('A', 2))
    const [metricSetB, setMetricSetB] = useState(initMetricSet('B', 4))

    // const [time, setTime] = useState(0);
    const [inputTime, setInputTime] = useState('');
    const [endTime, setEndTime] = useState(getConfigSpeed());
    // const [currentState, setCurrentState] = useState(0)
    // const [driveInfo, setDriveInfo] = useState(initValues());
    // const DELTA_INTERVAL = 1
    // const CALLBACK_INTERVAL = 20
    //todo: ratio delta vs callback

    let intervalId;

    const handleChangePace = (e) => {
        setInputTime(e.target.value);
    };

    const toggleTimer = () => {
        if (timerId < 0) {
            setTimerId(startTimer())
            //todo different states, different metrics
            //single callback to update multiple metrics with multiple states/behaviours 
        } else {
            clearInterval(timerId)
            setTimerId(NO_TIMER)
        }
    }

    const startTimer = () => {
        let behavior: Behavior = firstBehavior
        const TickMetric = (prevMetric: Metrics) => {
            let newMetric = behavior[prevMetric.currentState].tick(prevMetric, TICK_INCREMENT);
            if (newMetric.currentState !== prevMetric.currentState) {
                newMetric = behavior[newMetric.currentState].init(newMetric);
            }
            return newMetric;
        };
        const TickMetricSet = (behavior: Behavior) => (prevMetricSet: MetricsSet) => {
            let newMetricSet: MetricsSet = []
            for (let i = 0; i < prevMetricSet.length; i++) {
                const prevMetric = prevMetricSet[i]
                //l('tick train ', prevMetric.train, prevMetric.currentState, behavior[prevMetric.currentState].phaseName)
                let newMetric: Metrics
                do {
                    newMetric = behavior[prevMetric.currentState].tick(prevMetric, TICK_INCREMENT);
                    if (newMetric.currentState !== prevMetric.currentState) {
                        newMetric = behavior[newMetric.currentState].init(newMetric);
                    }
                    while (newMetric.pendingCalc > 0) {
                        //l('pending calc train ', newMetric.train, newMetric.pendingCalc)
                        newMetric = behavior[prevMetric.currentState].tick(prevMetric, newMetric.pendingCalc);
                        if (newMetric.currentState !== prevMetric.currentState) {
                            newMetric = behavior[newMetric.currentState].init(newMetric);
                        }
                    }
                } while (newMetric.pendingCalc > 0);

                newMetricSet.push(newMetric)
            }
            return newMetricSet
        };
        intervalId = setInterval(() => {
            //todo extract as a function
            // setMetric(TickMetric)
            setMetricSetA(TickMetricSet(traditionalMetroBehavior))
            setMetricSetB(TickMetricSet(fastMetroBehavior))
            // setMetricSetB(TickMetricSet(behaviorSet[1]))
        }, endTime)
        l('intervalId', intervalId)
        return intervalId
    };

    function handleAll() {
        l('started')
        let currentMetric = initMetrics('S', 0)
        while (firstBehavior[currentMetric.currentState].phaseName !== END_OF_PROCESS) {
            currentMetric = firstBehavior[currentMetric.currentState].tick(currentMetric, TICK_INCREMENT)
            setMetric(prevMetric => {
                l(prevMetric.timeRelative, prevMetric.timeTotal, prevMetric.spaceRelative, prevMetric.spaceTotal)
                return firstBehavior[prevMetric.currentState].tick(prevMetric, TICK_INCREMENT)
            })
        }
        l('completed')
    }

    return (
        <>
            <input type="number" value={inputTime} onChange={handleChangePace} />
            <input type="number" value={endTime} onChange={(e) => {
                localStorage.setItem('speed', e.target.value)
                setEndTime(e.target.value)
            }} />
            <button onClick={toggleTimer}>{timerId < 0 ? 'Start Timer' : 'Stop Timer'}</button>

            <button onClick={handleAll} >Sim(not working)</button>
            <button onClick={()=>setEnabledA(!enabledA)} >A</button>
            <button onClick={()=>setEnabledB(!enabledB)} >B</button>
            <span>
                {/* <ul>
                    <li>station: {firstBehavior[metric.currentState].stationName}</li>
                    <li>phase: {firstBehavior[metric.currentState].phaseName}</li>
                    <li>time: {(metric && metric.timeTotal)} s</li>
                    <li>velocity: {metric && metric.velocity} m/s</li>
                    <li>acceleration: {metric && metric.acceleration} m/s^2</li>
                    <li>distance: {metric && metric.spaceTotal} m</li>
                    <li>_ cur state: {metric.currentState}</li>
                    <li>_ timerId: {timerId}</li>
                </ul> */}
                <MetroInfo behavior={firstBehavior} metric={metric} />
                <MetroInfo behavior={traditionalMetroBehavior} metric={metricSetA[0]} />
            </span>

            <br />
            <div style={{ position: 'absolute', borderRadius: 80, width: '720px', height: '11px', left: 218, top: 286, backgroundColor: 'grey' }} />
            {Array.from({ length: 22 }, (v, k) => 
            <div style={{ position: 'absolute', borderRadius: 80, width: '7px', height: '7px', left: 223+k*33.5, top: 288, backgroundColor: 'white' }} />
        
        )}
            {
                metricSetA.map(
                    metric =>
                        <div
                            className="point"
                            style={{ left: 220 + metric.directionSpace / 20, top: 300 - (metric.direction ? 20 : 0) }}
                        />)
            }
            {
                metricSetB.map(
                    metric =>
                        <div
                            
                            className="point"
                            //display:'none',
                            style={{   left: 220 + metric.directionSpace / 20, top: 305 - (metric.direction ? 31 : 0), backgroundColor: 'green' }}
                        />)
            }

            {/* <div
                className="point"
                style={{ left: 180 + metric.spaceTotal / 4, top: 255 }}
            />
            <div
                className="point"
                style={{ left: 180 + metricSetA[0].directionSpace / 20, top: 305 - (metricSetA[0].direction ? 10 : 0) }}
            />
            <div
                className="point"
                style={{ left: 180 + metricSetA[1].directionSpace / 20, top: 355 - (metricSetA[1].direction ? 10 : 0) }}
            /> */}
        </>
    )

}

export default Sim;

// useEffect(() => {
//     if (time <= 0) {
//         clearInterval(intervalId);
//         //   setValues([0, 0, 0, 0]);
//     } else {
//         //   const newValues = [
//         //     Math.floor(Math.random() * 100),
//         //     Math.floor(Math.random() * 100),
//         //     Math.floor(Math.random() * 100),
//         //     Math.floor(Math.random() * 100),
//         //   ];
//         //   setValues(newValues);
//         l('t2')
//     }
// }, [time]);

// useEffect(() => {
//     const intervalId = setInterval(() => {
//         l('tick ')
//         // Generate 4 random values
//         //   const newValues = [
//         //     Math.floor(Math.random() * 100),
//         //     Math.floor(Math.random() * 100),
//         //     Math.floor(Math.random() * 100),
//         //     Math.floor(Math.random() * 100),
//         //   ];
//         //   setValues(newValues);
//     }, 1000); // Update every second

//     return () => clearInterval(intervalId); // Cleanup interval on unmount
// }, []); // Empty dependency array to run effect only once on mount


////////////////////////
//connect
// import {Realm} from 'realm-web'
import * as Realm from "realm-web";
const l = (...args) => { console.log(...args) }//log

const APP_ID = 'data-swcfkwm'
const DB_USER_ID = 'ms'
const DB_PASS = 'DVirr7MLxA6VBv4X'
const DB_NAME = 'measures'
const COLLECTION_NAME = 'data'

export default syncData;

function GetLocalStorageItems() {
    let allItems = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key.startsWith('realm-web')) continue;
      if (key.startsWith('user')) continue;
      if (key.startsWith('speed')) continue;
      allItems.push(key);
      // let syncKey = key + '-' + localStorage.getItem('user')
      //compare by date/by Id, compare all
      // console.log(syncKey);
    }
    return allItems
  }
  

async function syncData() {
    let allItems = GetLocalStorageItems();
    let unSyncEntries = allItems.filter(x => !x.endsWith('/sync'))
    //add : error, origkey
    let res = unSyncEntries.map(x => {
        let data = localStorage.getItem(x)
        try {
            let dataObject = JSON.parse(data)
            dataObject.syncTime = (new Date()).toUTCString()
            let key = x + '-' + localStorage.getItem('user')
            if (process.env.REACT_APP_PREFIX !== '') { key = process.env.REACT_APP_PREFIX + '/' + key }
            try {
                let stat = dataObject.stops.reduce((acc, x) => { return { go: acc.go + x.durationGo, dwell: acc.dwell + x.durationStops } }, { go: 0, dwell: 0 })
                stat.dpct = stat.dwell / (stat.dwell + stat.go) * 100
                dataObject.stats = stat
            }
            catch (e) { }
            dataObject._id = key
            return dataObject
        } catch (e) { }
    })
    if (!!res.length) {
        try {
            await saveDataToDB(res)
            unSyncEntries.forEach(x => {
                localStorage.setItem(x + '/sync', localStorage.getItem(x))
                localStorage.removeItem(x)
            })
            alert('Synchronized')
        } catch (e) {
            l('saving error: ', e)
        }
    }else{
        alert('already in sync')
    }


}

const saveDataToDB = async (data) => {
    // await connectToDatabase();
    l('TESTT')

    const app = new Realm.App({ id: APP_ID });
    // Create an email/password credential
    const credentials = Realm.Credentials.emailPassword(DB_USER_ID, DB_PASS);
    // Authenticate the user
    const user = await app.logIn(credentials);
    // 'App.currentUser' updates to match the logged in user
    console.assert(user.id === app.currentUser.id);
    l('TESTT2')
    const mongo = app.currentUser.mongoClient("Cluster0").db(DB_NAME).collection(COLLECTION_NAME)
    // mongo.insertOne(data)
    await mongo.insertMany(data)
    l('inserted in db: ', await mongo.count())
}




/////////////////////////

import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { useParams } from 'react-router-dom';
import getStations from './stops';

const l = (...args) => { console.log(...args) }//log

function App() {
  const { id } = useParams();
  const stationsName = getStations(id)


  const [measure, setMeasure] = useState('')
  const [disabledStart, setDisabledStart] = useState(false)
  const [disabledStopped, setDisabledStopped] = useState(false)
  const [disabledGo, setDisabledGo] = useState(true)
  const [disabledEnd, setDisabledEnd] = useState(true)
  const [stop, setStop] = useState()
  const [stops, setStops] = useState([]);

  const [selectedOption, setSelectedOption] = useState('0');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // const handleBegin = () => {
  //   setMeasure(id + "-" + ((new Date()).toISOString()))
  //   setDisabledStart(true)
  //   setDisabledStopped(false)

  //   let stationNo = parseInt(selectedOption)
  //   setSelectedOption(stationNo - 1)
  // }

  const i = () => {
    l(stop)
  }

  const handleStop = () => {
    l(measure)
    const stopTime = new Date().getTime();
    if (!measure) {
      setMeasure(id + "-" + ((new Date()).toISOString()))
      setDisabledStart(true)
      setDisabledStopped(true)
      setDisabledGo(false)
      setDisabledEnd(true)

      // let stationNo = parseInt(selectedOption)
      // setSelectedOption(stationNo - 1)
    } else {
      stops[stops.length - 1].stop2Time = stopTime
      let stationNo = parseInt(selectedOption)
      setSelectedOption(stationNo + 1)
  
    }
    // if (stops.length) {
    //   l(stops)
    //   stops[stops.length - 1].stop2Time = stopTime
    //   l(stops)
    // }
    let st = { stopTime }
    setStop(st)
    setStops([...stops, st])
    setDisabledGo(false)
    setDisabledEnd(true)
    setDisabledStopped(true)
    l(selectedOption.length)
    l(selectedOption)
    l('----')
    // setSelectedOption(selectedOption + 1)
    l(st)

  }

  const handleGo = () => {
    l('on go stops: ', stops)
    setDisabledStopped(false)
    setDisabledGo(true)
    setDisabledEnd(false)
    const goTime = new Date().getTime();
    stop.goTime = goTime
    stop.stationsName = stationsName[selectedOption]
    l(stops)

  }

  const handleEnd = () => {
    setDisabledStart(false)
    setDisabledStopped(true)
    setDisabledEnd(true)
    const stopTime = new Date().getTime();
    stops[stops.length - 1].stop2Time = stopTime
    stops.forEach((s, i) => {
      s.durationStops = s.goTime - s.stopTime
      s.durationGo = s.stop2Time - s.goTime
      // s.name = stationsName[i]
    })
    //Time in stops
    //Travel time

    let r = { measure, stops }
    localStorage.setItem(measure, JSON.stringify(r))
    let stat = stops.reduce((acc, x) => { return { go: acc.go + x.durationGo, dwell: acc.dwell + x.durationStops } }, { go: 0, dwell: 0 })
    alert(JSON.stringify(stat))
    l(r)

  }


  return (
    <div className="App">
      {/* <div className="grid-container">
        <button className="small-button">Small Button</button>
        <button className="medium-button">Medium Button</button>
        <button className="large-button">Large Button</button>
        <button className="extra-large-button">Extra Large Button</button>
      </div> */}

      <header className="App-header">

        <select value={selectedOption} onChange={handleSelectChange} disabled={disabledStart}>
          {stationsName.map((x, i) => <option value={i} key={x} >{x}</option>)}
        </select>

        <p />

        <div>
          {/* <button onClick={handleBegin} disabled={disabledStart}>Begin trip</button> */}
          <span />
          <button onClick={handleEnd} disabled={disabledEnd}>End trip</button>

        </div>
        <p></p>
        {/* <img src={logo} className={`App-logo, ${ disabledGo ? 'App-logo2' : ''}`} alt="logo" /> */}
        <img src={logo} className={ disabledStopped ? `App-logo2`:'App-logo'} alt="logo" />
        <p />
        {/* {clickTimes.length > 0 && (
          <div>
            <p>Time between clicks: {clickTimes.length > 1 ? clickTimes[clickTimes.length - 1] - clickTimes[clickTimes.length - 2] : 0} milliseconds</p>
            <p>Average time between clicks: {averageTime.toFixed(2)} milliseconds</p>
          </div>
        )} */}
        <button onClick={handleStop} disabled={disabledStopped}>Arrived</button>
        <p />
        <button onClick={handleGo} disabled={disabledGo}>Departed</button>
      </header>
    </div>
  );
}

export default App;




/*


saving
loading



function App() {
  const [clickTimes, setClickTimes] = useState([]);
  const [averageTime, setAverageTime] = useState(0);

  const handleClick = () => {
    const currentTime = new Date().getTime();
    const prevTime = clickTimes[clickTimes.length - 1];
    const timeDifference = prevTime ? currentTime - prevTime : 0;

    setClickTimes([...clickTimes, currentTime]);

    if (clickTimes.length > 0) {
      const sum = clickTimes.reduce((acc, time) => acc + time, 0);
      const avg = (sum + timeDifference) / (clickTimes.length + 1);
      setAverageTime(avg);
    }
  };

  return (
    <div className="App">
      <h1>Click Timer</h1>
      <button onClick={handleClick}>Click Me</button>
      {clickTimes.length > 0 && (
        <div>
          <p>Time between clicks: {clickTimes.length > 1 ? clickTimes[clickTimes.length - 1] - clickTimes[clickTimes.length - 2] : 0} milliseconds</p>
          <p>Average time between clicks: {averageTime.toFixed(2)} milliseconds</p>
        </div>
      )}
    </div>
  );
}

function App() {
  const [message, setMessage] = useState('Hello, World!');

  const handleClick = () => {
    setMessage('Button Clicked!');
  };

  return (
    <div className="App">
      <h1>{message}</h1>
      <button onClick={handleClick}>Click Me</button>
    </div>
  );
}
*/





// if ('Accelerometer' in window) {
//   const acl = new Accelerometer({ frequency: 60 });

//   acl.onreading = () => {
//     console.log(`Acceleration along the X-axis ${acl.x}`);
//     console.log(`Acceleration along the Y-axis ${acl.y}`);
//     console.log(`Acceleration along the Z-axis ${acl.z}`);
//   };

//   acl.onerror = (error) => {
//     console.error('Accelerometer error:', error);
//   };

//   acl.start();
// } else {
//   console.error('Accelerometer API not supported in this browser.');
// }


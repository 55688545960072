
//La Courneuve – 8 Mai 1945 - Maison Blanche

export default function getStations(i) {
    if (i === "genoa-r") {
        return [...getStations("genoa-d")].reverse()
    }
    else if (i === "genoa-d") {
        return [
            "Canepari",
            "Brin",
            "Dinegro",
            "Principe",
            "Darsena",
            "San Giorgio",
            "Sarzano/Sant'Agostino",
            "De Ferrari",
            "Brignole"]

    }
    else if (i === "barcelona-r-l3") {
        return [...getStations("barcelona-d-l3")].reverse()
    }
    else if (i === "barcelona-d-l3") {
        return ["Zona Universitària",
            "Palau Reial",
            "Maria Cristina",
            "Les Corts",
            "Plaça del Centre",
            "Sants Estació",
            "Tarragona",
            "Plaça d'Espanya",
            "Poble Sec",
            "Paral·lel",
            "Drassanes",
            "Liceu",
            "Plaça de Catalunya",
            "Passeig de Gràcia",
            "Diagonal",
            "Fontana",
            "Lesseps",
            "Vallcarca",
            "Penitents",
            "Vall d'Hebron",
            "Montbau",
            "Mundet",
            "Valldaura",
            "Canyelles",
            "Roquetes",
            "Trinitat Nova"]
    }
    else if (i === "barcelona-d-l2") {
        return ["Sant Antoni"
            , "Universitat"
            , "Passeig de Gràcia"
            , "Tetuan"
            , "Monumental"
            , "Sagrada Família"
            , "Encants"
            , "Clot"
            , "Bac de Roda"
            , "Sant Martí"
            , "La Pau"
            , "Verneda"
            , "Artigues | Sant Adrià"
            , "Sant Roc"
            , "Gorg"
            , "Pep Ventura"
            , "Badalona Pompeu Fabra"
        ]
    }
    else if (i === "barcelona-r-l2") {
        return [...getStations("barcelona-d-l2")].reverse();
    }
    else if (i === "paris-7-d") {
        return [
            "La Courneuve - 8 Mai 1945",
            "Fort d’Aubervilliers",
            "Aubervilliers - Pantin - Quatre Chemins",
            "Porte de la Villette",
            "Corentin Cariou",
            "Crimée",
            "Riquet",
            "Stalingrad",
            "Louis Blanc", //7bis
            "Château-Landon",
            "Gare de l’Est",
            "Poissonnière",
            "Cadet",
            "Le Peletier",
            "Chaussée d’Antin - La Fayette",
            "Opéra",
            "Pyramides",
            "Palais Royal - Musée du Louvre",
            "Pont Neuf",
            "Châtelet",
            "Pont Marie",
            "Sully - Morland",
            "Jussieu",
            "Place Monge",
            "Censier - Daubenton",
            "Les Gobelins",
            "Place d’Italie",
            "Tolbiac",
            "Maison Blanche",
        ]
    } else if (i === "paris-7-r") {
        return [...getStations("paris-7-d")].reverse()
    } else if (i === "paris-14-d") {
        return [
            "Mairie de Saint-Ouen",
            "Saint-Ouen",
            "Porte de Clichy",
            "Pont Cardinet",
            "Saint-Lazare",
            "Madeleine",
            "Pyramides",
            "Châtelet",
            "Gare de Lyon",
            "Bercy",
            "Cour Saint-Émilion",
            "Seine",
            "Bibliothèque François Mitterrand",
            "Olympiades"
        ]
    } else if (i === "paris-14-r") {
        return [...getStations("paris-14-d")].reverse()
    } else if (i === "milano-m1-d") {
        return [
            "Sesto Rondò",
            "Sesto Marelli",
            "Villa San Giovanni",
            "Precotto",
            "Gorla",
            "Turro",
            "Rovereto",
            "Pasteur",
            "Loreto",
            "Lima",
            "Porta Venezia",
            "Palestro",
            "San Babila",
            "Duomo",
            "Cordusio",
            "Cairoli",
            "Cadorna",
            "Conciliazione",
            "Pagano",
            "Buonarroti",
            "Amendola",
            "Lotto",
            "QT8",
            "Lampugnano",
            "Uruguay",
            "Bonola",
            "San Leonardo",
            "Molino Dorino",
            "Pero",
            "Rho Fieramilano",
            // "Wagner",
            // "De Angeli",
            // "Gambara",
            // "Bande Nere",
            // "Primaticcio",
            // "Inganni",
            // "Bisceglie"
        ]
    } else if (i === "milano-m1-r") {
        return [...getStations("milano-m1-d")].reverse()
    } else if (i === "milano-m2-d") {
        return [
            "Gessate",
            "Cascina Antonietta",
            "Gorgonzola",
            "Villa Pompea",
            "Bussero",
            "Cassina de' Pecchi",
            "Villa Fiorita",
            "Cernusco sul Naviglio",
            "Cascina Burrona",
            "Vimodrone",
            "Cologno Nord",
            "Cologno Centro",
            "Cologno Sud",
            "Cascina Gobba",
            "Crescenzago",
            "Cimiano",
            "Udine",
            "Lambrate FS",
            "Piola",
            "Loreto",
            "Caiazzo",
            "Centrale FS",
            "Gioia",
            "Garibaldi FS",
            "Moscova",
            "Lanza",
            "Cadorna FN",
            "Sant'Ambrogio",
            "Sant'Agostino",
            "Porta Genova FS",
            "Romolo",
            "Famagosta"
        ]
    } else if (i === "milano-m2-r") {
        return [...getStations("milano-m2-d")].reverse()
    } else if (i === "milano-m3-d") {
        return [
            "Comasina",
            "Affori FN",
            "Affori Centro",
            "Dergano",
            "Maciachini",
            "Zara",
            "Sondrio",
            "Centrale FS",
            "Repubblica",
            "Turati",
            "Montenapoleone",
            "Duomo",
            "Missori",
            "Crocetta",
            "Porta Romana",
            "Lodi T.I.B.B.",
            "Brenta",
            "Corvetto",
            "Porto di Mare",
            "Rogoredo FS",
            "San Donato"
        ]
    } else if (i === "milano-m3-r") {
        return [...getStations("milano-m3-d")].reverse()
    } else if (i === "milano-m4-d") {
        return [
            "San Cristoforo FS",
            "Segneri",
            "Gelsomini",
            "Frattini",
            "Tolstoj",
            "Bolivar",
            "California",
            "Coni Zugna",
            "Sant'Ambrogio",
            "De Amicis",
            "Vetra",
            "Santa Sofia",
            "Sforza-Policlinico",
            "San Babila",
            "Tricolore",
            "Dateo",
            "Susa",
            "Argonne",
            "Stazione Forlanini",
            "Repetti",
            "Linate Aeroporto"
        ]
    } else if (i === "milano-m4-r") {
        return [...getStations("milano-m4-d")].reverse()
    } else if (i === "milano-m5-d") {
        return [
            "Bignami",
            "Ponale",
            "Bicocca",
            "Ca’ Granda",
            "Istria",
            "Marche",
            "Zara",
            "Isola",
            "Garibaldi FS",
            "Monumentale",
            "Cenisio",
            "Gerusalemme",
            "Domodossola",
            "Tre Torri",
            "Portello",
            "Lotto",
            "Segesta",
            "San Siro Ippodromo",
            "San Siro Stadio"
        ]
    }else if (i==="milano-m5-r"){
        return [...getStations("milano-m5-d")].reverse()
    }

    else return []
}

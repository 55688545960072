import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import User from "./User.tsx";
import syncData from "./datasync.js"; 

const USER = 'user'
function Root() {
    let user = localStorage.getItem(USER)
    if (!user) {
        return <User />
    } else
        return (
            <div className="App">
                <nav>
                    <ul>
                        <li>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/genoa-d">Genoa (Canepari to Brignole)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/genoa-r">Genoa (Brignole to Canepari)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m1-d">Milano M1 D(Sesto Rondò-Rho Fieramilano)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m1-r">Milano M1 R(Rho Fieramilano-Sesto Rondò)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m2-d">Milano M2 D(Gessate-Famagosta)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m2-r">Milano M2 R(Famagosta-Gessate)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m3-d">Milano M3 D(Comasina-San Donato)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m3-r">Milano M3 R(San Donato-Comasina)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m4-d">Milano M4 D(San Cristoforo FS-Linate Aeroporto)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m4-r">Milano M4 R(Linate Aeroporto-San Cristoforo FS)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m5-d">Milano M5 D(Bignami-San Siro Stadio)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/milano-m5-r">Milano M5 R(San Siro Stadio-Bignami)</NavLink>
                        </li>                        
                        <li>
                            <NavLink to="/z/paris-7-d">Paris 7 D(La Courneuve - 8 Mai 1945 - Maison Blanche)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/paris-7-r">Paris 7 R (Maison Blanche - La Courneuve - 8 Mai 1945)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/paris-14-d">Paris 14 D (Mairie de Saint-Ouen-Olympiades)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/paris-14-r">Paris 14 R (Olympiades-Mairie de Saint-Ouen)</NavLink>
                        </li>
                        <li>
                            <NavLink to="/z/barcelona-d-l2">Barcelona L2</NavLink>
                        </li>
                        {/* <li>
                        <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact">Contact</NavLink>
                    </li> */}
                    </ul>
                </nav>
            <br/>
            <button onClick={syncData}>sync data</button>
            </div>

        );
}

export default Root;
